var render = function render(){var _vm=this,_c=_vm._self._c;return _c('i',_vm._b({class:[
		'c-icon',
		'icon',
		_vm.isIcon ? 'i-simple': '',
		_vm.isComplex ? 'i-complex' : '',
		`i-${_vm.iconName}`
	]},'i',_vm.iAttrs,false),[_c(_vm.dynamicSvg,_vm._b({tag:"component"},'component',_vm.svgAttrs,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
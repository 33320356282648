<template>
	<!--
		This wrapping <i> is unfortunately necessary due to a bug which
		causes Vue not to properly server-side render class and other attributes
		on dynamically loaded components.
	-->
	<i
		:class="[
			'c-icon',
			'icon',
			isIcon ? 'i-simple': '',
			isComplex ? 'i-complex' : '',
			`i-${iconName}`
		]"
		v-bind="iAttrs"
	>
		<component :is="dynamicSvg" v-bind="svgAttrs" />
	</i>
</template>

<script>
	export default {
		inheritAttrs: false,
		props: {
			svg: {
				type: String,
				required: true
			}
		},
		computed: {
			iAttrs() {
				const { style, class: className } = this.$attrs;
				return { style, class: className };
			},
			svgAttrs() {
				const { style: _, class: __, ...attrs } = this.$attrs;
				return attrs;
			},
			isIcon() {
				return this.svg.indexOf('/') === -1;
			},
			isComplex() {
				return this.svg.indexOf('complex-icons') > -1;
			},
			processedSvg() {
				const svg = this.svg;
				if (svg === 'cog') return 'loading';

				return svg;
			},
			dynamicSvg() {
				if (this.isIcon) {
					// eslint-disable-next-line @typescript-eslint/no-var-requires
					return require(
						`../../assets/graphics/icons/${this.processedSvg}.svg?inline-svg`
					).default;
				}
				// eslint-disable-next-line @typescript-eslint/no-var-requires
				return require(
					`../../assets/graphics/${this.processedSvg}.svg?inline-svg`
				).default;
			},
			iconName() {
				return this.processedSvg.split('/').slice(-1);
			}
		}
	};
</script>

<style lang="scss">
.c-icon {
	display: inline-block;
	line-height: 0;

	// nested svg element should always be the same size as its parent
	:root:root:root & svg {
		display: block;
		width: 100%;
		height: 100%;
	}
}
</style>

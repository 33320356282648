/**
 * Converts the given absolute URL to a relative URL
 * Credit: https://stackoverflow.com/questions/6263454/get-relative-url-from-absolute-url/6263526#6263526
 * @param url The absolute URL to convert
 */
export function convertAbsoluteUrlToRelative(url: string): string {
	return url.replace(/^(?:\/\/|[^/]+)*\//, '/');
}

export type QueryParams = {
	[key: string]: string
};

/**
 * Returns a query string (including ?) based on the provided key/value pairs.
 * @param QueryParams The params
 */
export function buildQueryString(params: QueryParams) {
	const keys = Object.keys(params);
	if (!keys.length)
		return '';
	return '?' + keys.map(k => `${k}=${encodeURIComponent(params[k] === null || params[k] === undefined ? '' : params[k])}`).join('&');
}

const slashDupes = /[/]+/g;
/**
 * Removes repeated forward slash characters from the provided url.
 * @param url An absolute or relative url.
 */
export function cleanUrlPath(url: string): string {
	return url.replaceAll(slashDupes, '/');
}
import type { RouteConfig } from 'vue-router';

const ImporterRoutes: RouteConfig[] = [{
	path: '/l/importers',
	component: () => import(/* webpackChunkName: 'Integration' */ 'src/layouts/Integration.vue'),
	meta: {
		auth: 'required'
	},
	// don't allow direct access to this route
	redirect: { name: '404' },
	children: [
		{
			name: 'jotform-importer',
			path: 'jotform',
			component: () => import(/* webpackChunkName: 'JotformImporter' */ './jotform/JotformImporter.vue')
		}
	]
}];

export default ImporterRoutes;

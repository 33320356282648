/**
 * Checks whether the given object is an `Error` object
 * @param err The object to check
 */
export function isError(obj: any): obj is Error {
	return typeof obj === 'object' && Object.prototype.toString.call(obj) === '[object Error]';
}

/**
 * Checks whether the given object is a `Date` object
 * @param obj The object to check
 */
export function isDate(obj: any): obj is Date {
	return typeof obj === 'object' && Object.prototype.toString.call(obj) === '[object Date]';
}

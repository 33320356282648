import Vue from 'vue';
export function disableTransitions() {
	const div = {
		functional: true,
		render(h, context) {
			return context.children;
		}
	};
	Vue.component('Transition', div);
	Vue.component('TransitionGroup', div);
}

import { serviceRequest } from './service-request';

export function reportReferral() {
	if (localStorage) {
		let referrer = document.referrer;
		let destination = location.href;

		// See index.template.html for logic which captures referrer-info on page load
		const referrerInfo = localStorage.getItem('referrer-info');
		if (referrerInfo) {
			const split = referrerInfo.split('|');
			referrer = split[1];
			destination = split[2];
		}

		return serviceRequest('svc/referrals/report-referral', {
			referringUrl: referrer,
			destinationUrl: destination
		});
	}
}

export function getReferringOrganizationName(crs?: string) {
	return serviceRequest('svc/referrals/organization-name', null, { method: 'GET', query: crs ? { crs } : null });
}

import { isWithinFrame } from '@cognitoforms/utils/utilities';

// Detect that the site is being hosted in a frame and break out if needed
if (isWithinFrame()) {
	// Attempt to get the parent window's hostname, which will fail if the parent is not same-origin
	let parentHostname = null;
	try {
		parentHostname = window.parent.location.hostname;
	}
	catch (e) {
		// Ignore error attempting to access the parent frame, i.e. "Blocked a frame with origin "???" from accessing a cross-origin frame.
	}

	// If the containing page is on the same domain (i.e. the Cognito chrome), then force it to navigate to the page we're currently on
	if (parentHostname === window.location.hostname) {
		window.parent.location.assign(window.location.href);
	}
}

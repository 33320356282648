import { serviceRequest } from './service-request';
import session, { getActiveSession } from './session';

export function getSessionInfo(passive: boolean = false) {
	return serviceRequest('svc/session/info', null, {
		method: 'GET',
		passive
	});
}

export function changeOrganization(id: string) {
	return serviceRequest('svc/session/change-organization', { id });
}

export async function logout() {
	await serviceRequest('svc/session/logout', {}, { method: 'POST', useSubmissionToken: true });

	session.value = null;

	// refetch info for the now anonymous session
	await getActiveSession();
}

export type AutoLogoutResponse = {
	requiredAuthMethod: string | null,
	requiredAuthProvider: string | null,
	orgCode: string | null,
	orgName: string | null
}

export async function autoLogout(csrfToken: string): Promise<AutoLogoutResponse> {
	const res = await serviceRequest('svc/session/autologout', {}, { method: 'POST', csrfToken: csrfToken });

	session.value = null;

	// refetch info for the now anonymous session
	await getActiveSession();

	return res;
}

export function getOrganizations() {
	return serviceRequest('svc/session/organizations', null, { method: 'GET' });
}

// Get copy permissions to check if the user can copy a template instead of sending a 500
export function getCopyPermissions(orgId: string, folderId: string) {
	return serviceRequest('svc/session/copy-permissions', { orgId, folderId }, { method: 'POST' });
}
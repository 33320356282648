
export type BotChallengeData = {
	map: number[];
	chars: string[];
}
export enum BotProtectionMethod {
	NoBots = 'X-NoBots',
	Recaptcha = 'X-Recaptcha'
}

export type BotProtectionEvidence = {
	method: BotProtectionMethod;
	token: string;
}

export enum UserType {
	Unknown = 'unknown',
	Bot = 'bot',
	Human = 'human'
}

type BotProtectionCallback = (botProtection: BotProtection) => void;

export class BotProtection {
	private _challenge: BotChallengeData;
	private _botDetected = false;
	private _evidence = new Map<BotProtectionMethod, BotProtectionEvidence>();
	private _detectionCallbacks: BotProtectionCallback[] = [];

	constructor(challenge: BotChallengeData) {
		this._challenge = challenge;
	}

	get challenge() {
		return this._challenge.map.map(i => this._challenge.chars[i]).join('');
	}

	whenActivityDetected(callback: (botProtection: BotProtection) => void) {
		this._detectionCallbacks.push(callback);
		callback(this);
	}

	get userType() {
		if (this._botDetected)
			return UserType.Bot;
		else if (this._evidence.size > 0)
			return UserType.Human;
		else
			return UserType.Unknown;
	}

	getEvidence(method: BotProtectionMethod) {
		return this._evidence.get(method);
	}

	reportBot() {
		this._botDetected = true;
		this.notifyListeners();
	}

	reportHuman(evidence: BotProtectionEvidence) {
		this._botDetected = false;

		this._evidence.set(evidence.method, evidence);

		this.notifyListeners();
	}

	private notifyListeners() {
		this._detectionCallbacks.forEach(cb => cb(this));
	}
}